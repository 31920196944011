<template>
  <v-data-table
    :class="showClosedTickets ? '' : 'collapsed'"
    :headers="headers"
    :items="closedTickets"
    :custom-sort="customSort"
    data-cy="support-history-ticket-table"
  >
    <template #top>
      <v-toolbar
        flat
        color="white"
      >
        <v-toolbar-title>
          <span v-t="'support.history'" />
          {{ localizedClosedTickets }}
          {{
            clientClosedTicketsCount > 0
              ? $t("support.lasClosedOn_dd/mm/yyyy", {
                lastClosedTicketDate: clientLastClosedTicketDate
              })
              : emptyString
          }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          rounded
          depressed
          text
          data-cy="support-history-ticket-button"
          @click="toggleShowTickets"
        >
          {{ buttonName }}
        </v-btn>
      </v-toolbar>
      <v-divider />
    </template>
    <template #item.state="{ item }">
      <v-icon
        class="mr-2"
      >
        {{ item.state === stateDone ? doneIcon : canceledIcon }}
      </v-icon>
      <span v-t="`support.${camelize(item.state)}`" />
    </template>
    <template #item.lastUpdate="{ item }">
      <span
        v-if="item.state === stateDone"
        v-t="'support.closed'"
      />
      {{ item.lastUpdateDateTime | fromNow(currentLocale) }}
    </template>
    <template #item.actions="{ item }">
      <router-link :to="{name: ROUTE_TICKET_MODAL, params: {ticketId: item.id}}">
        <v-btn rounded
          depressed
          color="primary"
          data-cy="support-ticket-show-details-button"
        >
          {{ $t('support.showTicketDetails') }}
        </v-btn>
      </router-link>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import moment from 'moment'
import {
  DONE_ICON,
  CANCELED_ICON,
  TICKET_STATE_CANCELED,
  TICKET_STATE_CANCELED_BY_CLIENT,
  TICKET_STATE_DONE
} from '../../../constants/app'
import { CLOSED_TICKET_HEADERS } from '../../../constants/support'
import { ROUTE_TICKET_MODAL } from '@/constants/routeNames'

export default {
  filters: {
    fromNow: function (date, locale) {
      return moment(date)
        .locale(locale)
        .fromNow()
    }
  },
  data () {
    return {
      stateDone: TICKET_STATE_DONE,
      stateCanceled: TICKET_STATE_CANCELED,
      stateCanceledByClient: TICKET_STATE_CANCELED_BY_CLIENT,
      doneIcon: DONE_ICON,
      canceledIcon: CANCELED_ICON,
      emptyString: '',
      ROUTE_TICKET_MODAL
    }
  },
  computed: {
    ...mapGetters('support', {
      closedTicketsGetter: 'closedTickets',
      lastClosedTicketDateGetter: 'lastClosedTicketDate'
    }),
    ...mapState('support', ['showClosedTickets']),
    clientId () {
      return this.$route.params.clientId
    },
    closedTickets () {
      return this.closedTicketsGetter(this.clientId)
    },
    clientClosedTicketsCount () {
      return this.closedTickets.length
    },
    clientLastClosedTicketDate () {
      return this.$options.filters.formatDate(this.lastClosedTicketDateGetter(this.clientId))
    },
    buttonName () {
      return this.$t(this.showClosedTickets ? 'hide' : 'show')
    },
    headers () {
      return CLOSED_TICKET_HEADERS.map(header => {
        header.text = this.$i18n.t(header.text)
        return header
      })
    },
    localizedClosedTickets () {
      return `- ${this.clientClosedTicketsCount} ${this.$tc('support.nClosedTickets', this.clientClosedTicketsCount)}`
    },
    currentLocale () {
      return this.$route.params.locale
    }
  },
  methods: {
    ...mapMutations('support', ['toggleShowTickets']),
    camelize (string) {
      return this.$options.filters.camelize(string)
    },
    customSort (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === 'lastUpdate') {
          if (isDesc[0]) {
            return Date.parse(b.lastUpdateDateTime) - Date.parse(a.lastUpdateDateTime)
          }
          return Date.parse(a.lastUpdateDateTime) - Date.parse(b.lastUpdateDateTime)
        }
        if (!isDesc[0]) {
          return a[index] < b[index] ? -1 : 1
        }
        return b[index] < a[index] ? -1 : 1
      })
      return items
    }
  }
}
</script>
